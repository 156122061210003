import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'

import Bar from './Bar'
import Burger from './Burger'
import Drawer from './Drawer'
import Language from './Language'
import Logo from './Logo'
import Menu from './Menu'
import MenuDrawer from './MenuDrawer'
import SubMenu from './SubMenu'

// import MenuLandingpage from './MenuLandingpage'
// import LanguageSwitcher from './Switcher'

const Navigation = (props) => {
  const data = useStaticQuery(graphql`
    query TourNavigationQuery {
      pages: allContentfulPage {
        edges {
          node {
            contentful_id
            slug
            node_locale
          }
        }
      }
    }
  `)

  const locale = props.locale
  if (!locale) {
    console.error('no locale')
  }

  // const locales = props.locales

  const {
    location,
    pageContext: { otherLanguageSlug },
  } = props
  const [showDrawer, toggleDrawer] = useState(false)

  const getLinkById = (contentfulID) => {
    const slug = data.pages.edges.filter(
      ({ node }) =>
        node.contentful_id === contentfulID && node.node_locale === locale
    )[0].node.slug
    return `/${slug}/`
  }

  const generateLanguages = (slugs) => {
    return slugs
      .filter((n) => n)
      .map((slug) => {
        return {
          locale: slug.startsWith('/en') ? 'en' : 'de',
          slug,
        }
      })
      .sort((element1, element2) => {
        return element1.locale > element2.locale
      })
  }
  const languages = generateLanguages([location.pathname, otherLanguageSlug])

  return (
    <>
      <Bar>
        <Logo locale={locale} handleClick={() => toggleDrawer()} />
        <Menu pathname={location.pathname} getLinkById={getLinkById} />
        <Language className="hidden lg:block" languages={languages} />
        <Burger
          className="lg:hidden flex items-center text-white"
          handleClick={() => toggleDrawer(!showDrawer)}
        />
      </Bar>
      <SubMenu pathname={location.pathname} getLinkById={getLinkById} />
      <Drawer visible={showDrawer}>
        <MenuDrawer
          pathname={location.pathname}
          getLinkById={getLinkById}
          handleClick={() => toggleDrawer(!showDrawer)}
        />
        <Language
          className="mb-10 lg:hidden"
          languages={languages}
          handleClick={() => toggleDrawer(!showDrawer)}
        />
      </Drawer>
    </>
  )
}

export default Navigation
