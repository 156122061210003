import React from 'react'

const Bar = ({ children }) => {
  return (
    <nav className="lg:fixed lg:top-0 relative z-50 w-full bg-gray-800">
      <div className="flex flex-row flex-wrap lg:flex-nowrap mx-auto max-w-screen-xl px-4 md:px-6">
        {children}
      </div>
    </nav>
  )
}

export default Bar
