import React from 'react'

const Container = ({ children, style }) => (
  <div
    className="mx-auto px-4 md:px-6 mb-10 md:mb-16 lg:mb-20 max-w-screen-xl"
    style={style}
  >
    {children}
  </div>
)

export default Container
