import { FormattedMessage } from 'gatsby-plugin-react-intl'
import React from 'react'

const Burger = ({ className, handleClick }) => {
  return (
    <button
      type="button"
      aria-label="Burger menue"
      onClick={handleClick}
      className={className}
    >
      <FormattedMessage id="menu" />
      <span className="ml-2 w-4 space-y-1 block">
        <span className="block w-full h-0.5 bg-white"></span>
        <span className="block w-full h-0.5 bg-white"></span>
        <span className="block w-full h-0.5 bg-white"></span>
      </span>
      {/* <FaBars className="ml-2 text-white" size="1em" /> */}
    </button>
  )
}

export default Burger
