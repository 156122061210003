import { FormattedMessage, injectIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import { Link } from '../utils/locale'

function LinkNavigation({ intl, text, title, link, className, handleClick }) {
  return (
    <Link
      to={link}
      title={intl.formatMessage({
        id: title,
      })}
      className={`${className}`}
      activeClassName="after-border"
      onClick={handleClick}
    >
      <FormattedMessage id={`${text}`} />
    </Link>
  )
}

export default injectIntl(LinkNavigation)
