//, changeLocale
import { Link as GatsbyLink, navigate as gatsbyNavigate } from 'gatsby'
// import PropTypes from 'prop-types'
import { Link as IntlLink } from 'gatsby-plugin-react-intl'
import React from 'react'

const removePrefix = (pathname) => {
  const base =
    typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
  if (base && pathname.indexOf(base) === 0) {
    pathname = pathname.slice(base.length)
  }
  return pathname
}

export const removeTrailingSlash = (url) => {
  if (url === ' /') {
    return url
  }
  return url.replace(/\/$/, '')
}

const removeLocalePart = (pathname, routed) => {
  if (!routed) {
    return pathname
  }
  const i = pathname.indexOf(`/`, 1)
  return pathname.substring(i)
}

export const removeLocale = (pathname) => {
  if (typeof window === 'undefined') {
    return
  }
  const { routed } = window.___gatsbyIntl

  return removeLocalePart(removePrefix(pathname), routed)
}

export const changeLocale = (language, to) => {
  if (typeof window === 'undefined') {
    return
  }
  // const { routed } = window.___gatsbyIntl
  const routed = language === 'en'
  const pathname =
    to || removeLocalePart(removePrefix(window.location.pathname), routed)

  const link = `/${language}${pathname}${window.location.search}`
  localStorage.setItem('gatsby-intl-language', language)
  gatsbyNavigate(link)
}

export const Link = React.forwardRef((props, ref) => {
  if (props.language === 'de') {
    return <GatsbyLink ref={ref} {...props} />
  }
  return <IntlLink ref={ref} {...props} />
})

export const getLocaleFromLocation = (location) => {
  return location.pathname.indexOf('/en/') !== -1 ? 'en' : 'de'
}
