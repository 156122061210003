import { graphql, useStaticQuery } from 'gatsby'
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo'
// import { LocalBusinessJsonLd, LogoJsonLd } from 'gatsby-plugin-next-seo'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import logo from '../../../static/img/logo-name-white.svg'
import instagramIcon from '../../../static/img/social/Instagram.svg'
import facebookIcon from '../../../static/img/social/facebook.svg'
import pinterestIcon from '../../../static/img/social/pinterest.svg'
import tripadvisorIcon from '../../../static/img/social/tripadvisor.svg'
import twitterIcon from '../../../static/img/social/twitter.svg'
import Icon from '../../components/Icon'
import { Link } from '../../utils/locale'

// import ContactForm from '../../components/footer/ContactForm'

const Footer = ({ intl, locale }) => {
  const data = useStaticQuery(graphql`
    query FooterNavigationQuery {
      pages: allContentfulPage {
        edges {
          node {
            contentful_id
            slug
            node_locale
          }
        }
      }
    }
  `)
  const getLinkById = (contentfulID) => {
    const slug = data.pages.edges.filter(
      ({ node }) =>
        node.contentful_id === contentfulID && node.node_locale === locale
    )[0].node.slug
    return `/${slug}/`
  }

  return (
    <footer
      className="py-12 bg-gray-700 footer"
      style={{ contentVisibility: 'auto' }}
    >
      <div className="max-w-screen-xl px-4 mx-auto text-white md:px-6 standard-pad">
        <div className="flex flex-wrap w-full">
          <div className="w-full mb-16 md:w-1/3 md:pr-8 md:mb-0">
            <div className="flex items-center mb-2 footer-logo">
              <Icon
                svg={logo}
                className="pr-3 max-w-xxs w-full h-auto fill-current"
              />
              <div className="hidden" itemProp="name">
                Berlin Bootsverleih
              </div>
            </div>
            <div className="mb-5 ml-10">
              <p className="mb-3">
                <span>Knobelsdorffstr. 17</span>
                <br />
                <span>14059 Berlin</span>
              </p>
              <p className="mb-3">
                <FormattedMessage id="officeOpeningHours" />:
                <br />
                <FormattedMessage id="daily" /> 10:00 - 19:00
              </p>

              <p>
                <FormattedMessage id="pleaseMakeAnAppointment" />
              </p>
            </div>
            <div id="social_container" className="ml-10">
              <ul className="mb-12 list-unstyled">
                <li>
                  <Link
                    className="block pt-1 pb-1 mb-2 underline"
                    to={getLinkById('3dxpMgs8D1lb1qMqrszqij')}
                    title={intl.formatMessage({
                      id: 'jobOffers',
                    })}
                  >
                    <FormattedMessage id="jobOffers" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="block pt-1 pb-1 mb-2 underline"
                    to={getLinkById('5RlBffCn2oU80zcLglTZMf')}
                    title={intl.formatMessage({
                      id: 'imprint',
                    })}
                  >
                    <FormattedMessage id="imprint" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="block pt-1 pb-1 mb-2 underline"
                    to={getLinkById('3Ho6rf9k9YcYbj28P0YPrK')}
                    title={intl.formatMessage({
                      id: 'terms',
                    })}
                  >
                    <FormattedMessage id="terms" />
                  </Link>
                </li>
                <li>
                  <Link
                    className="block pt-1 pb-1 mb-2 underline"
                    to={getLinkById('52spP8lUKYwhuhOT5ThbVs')}
                    title={intl.formatMessage({
                      id: 'privacyDisclaimer',
                    })}
                  >
                    <FormattedMessage id="privacyDisclaimer" />
                  </Link>
                </li>
                <li>
                  <button
                    className="block pt-1 pb-1 mb-2 underline"
                    onClick={() => {
                      window.cookiehub.openSettings()
                    }}
                    title={intl.formatMessage({
                      id: 'privacySettings',
                    })}
                    aria-label={intl.formatMessage({
                      id: 'privacySettings',
                    })}
                  >
                    <FormattedMessage id="privacySettings" />
                  </button>
                </li>
              </ul>
              <div className="social">
                {/*  <link itemProp="telephone" href="tel:+15048752225">
                            <link itemProp="hasMap" href="https://goo.gl/maps/AR1NpRZxz5o"> */}
                <a
                  className="inline-block px-2 py-2 mr-2 text-center bg-white rounded"
                  href="https://www.facebook.com/BerlinBoot"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Facebook"
                  aria-label="Facebook"
                >
                  <Icon svg={facebookIcon} className="fill-current w-8 h-8" />
                </a>
                <a
                  className="inline-block px-2 py-2 mr-2 text-center bg-white rounded"
                  href="https://twitter.com/Berlin_Boat_com"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Twitter"
                  aria-label="Twitter"
                >
                  <Icon svg={twitterIcon} className="fill-current w-8 h-8" />
                </a>
                <a
                  className="inline-block px-2 py-2 mr-2 text-center bg-white rounded"
                  href="https://www.instagram.com/berlin_bootsverleih_com/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Instagram"
                  aria-label="Instagram"
                >
                  <Icon svg={instagramIcon} className="fill-current w-8 h-8" />
                </a>
                <a
                  className="inline-block px-2 py-2 mr-2 text-center bg-white rounded"
                  href="https://www.tripadvisor.de/Attraction_Review-g187323-d7144138-Reviews-Berlin_Bootsverleih-Berlin.html"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Tripadvisor"
                  aria-label="Tripadvisor"
                >
                  <Icon
                    svg={tripadvisorIcon}
                    className="fill-current w-8 h-8"
                  />
                </a>
                <a
                  className="inline-block px-2 py-2 mr-2 text-center bg-white rounded"
                  href="https://www.pinterest.de/277au0zidq5j05vggjw0vgyzso73jy"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Pinterest"
                  aria-label="Pinterest"
                >
                  <Icon svg={pinterestIcon} className="fill-current w-8 h-8" />
                </a>
              </div>
            </div>
          </div>
          <div id="contact" className="w-full md:w-2/3 md:pl-8">
            <div className="mt-0 mb-2 text-4xl font-title">
              <FormattedMessage id="contact" />
            </div>

            <p className="mb-10">
              <FormattedMessage id="footerText" />
            </p>

            {/* <ContactForm id="contact-form" locale={locale} /> */}
            <Link
              className="text-gray-700 bg-white hover:bg-gray-200 h-12 inline-flex items-center px-8 pb-1 text-lg leading-none rounded font-title md:text-xl"
              to={getLinkById('1MCPxyOPRZmaMwQILIvHCR')}
              title={intl.formatMessage({
                id: 'privacyDisclaimer',
              })}
            >
              <FormattedMessage id="toContactForm" />
            </Link>
            {/* <ContactForm id="contact-form" locale={locale} /> */}
            <LocalBusinessJsonLd
              type="LocalBusiness"
              id="https://www.berlin-bootsverleih.com/"
              name="Berlin Bootsverleih"
              description="Vergleichen Sie Boote verschiedener Anbieter und mieten Sie das perfekte Schiff für Ihr Event. Schippern Sie mit einem Partyboot über die Spree oder mit einem Floß über die Havel. Genießen Sie einen romantischen Urlaub auf einem Hausboot mit Ihren Liebsten. Das Team von Berlin Bootsverleih steht Ihnen gerne bei der Planung Ihrer Bootstour zur Seite."
              url="https://www.berlin-bootsverleih.com/"
              telephone="+49 30 92107589"
              priceRange="€€"
              openingHours={{
                opens: '10:00:00',
                closes: '19:00:00',
                dayOfWeek: [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sundday',
                ],
              }}
              address={{
                streetAddress: 'Knobelsdorffstr. 17',
                addressLocality: 'Berlin',
                postalCode: '14059',
                addressCountry: 'Germany',
              }}
              geo={{
                latitude: '40.75',
                longitude: '73.98',
              }}
              images={['https://www.berlin-bootsverleih.com/img/masthead6.jpg']}
            />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default injectIntl(Footer)
