import { LogoJsonLd } from 'gatsby-plugin-next-seo'
import { IntlContextProvider, IntlProvider } from 'gatsby-plugin-react-intl'
import React from 'react'

import Footer from '../components/footer/footer'
import Navigation from '../components/navigation'
// import { LocalBusinessJsonLd, LogoJsonLd } from 'gatsby-plugin-next-seo'
import '../styles/main.css'

const Layout = (props) => {
  const children = props.children
  const language = props.pageContext.language
  const intl = props.pageContext.intl

  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLanguage}
      messages={intl.messages}
    >
      <IntlContextProvider value={intl}>
        <LogoJsonLd
          logo="https://www.berlin-bootsverleih.com/img/logo/berlin-bootsverleih.svg"
          url="https://www.berlin-bootsverleih.com"
        />
        <div className="antialiased text-gray-800 font-regular">
          <Navigation {...props} locale={language} />
          <div>{children}</div>
          <Footer locale={language} />
        </div>
      </IntlContextProvider>
    </IntlProvider>
  )
}
export default Layout
