import { Link } from 'gatsby'
import React from 'react'

import logo from '../../images/icons/logo-name-white.svg'
import Icon from '../Icon'

const Logo = ({ locale, handleClick }) => {
  return (
    <Link
      className="flex content-center flex-1 text-lg text-white md:mr-20 font-title py-2 pr-3"
      to={locale === 'de' ? '/' : '/en'}
      onClick={handleClick}
      title="Berlin Bootsverleih | Mieten Sie ein Boot, Floß, Hausboot oder Schiff in Berlin. Wir bieten Bootstouren für Stadtrundfahrten, Events, Junggesellenabschiede und Partys."
    >
      <Icon svg={logo} className="lg:h-10 md:h-9 h-8 fill-current" />

      {/* <img
        alt="Berlin Bootsverleih Logo"
        className="lg:h-10 md:h-9 h-8 text-white fill-current"
        height="32"
        src={LogoSVG}
        width="186"
      /> */}
    </Link>
  )
}

export default Logo
