import { injectIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import LinkNavigation from '../LinkNavigation'

function MenuDrawer({ getLinkById, handleClick }) {
  return (
    <>
      <LinkNavigation
        className="px-3 pt-4 pb-3 text-xl text-white md:text-2xl font-title last:mr-0"
        text="boats"
        title="boats"
        link={getLinkById('6kFcRootiIWCOqPktT3NlD')}
        handleClick={handleClick}
      />
      <LinkNavigation
        className="px-3 pt-4 pb-3 text-xl text-white md:text-2xl font-title last:mr-0"
        text="boatTours"
        title="boatTours"
        link={getLinkById('23KjwgDylEv6dBb2apqe0S')}
        handleClick={handleClick}
      />
      <LinkNavigation
        className="px-3 pt-4 pb-3 text-xl text-white md:text-2xl font-title last:mr-0"
        text="faq"
        title="faq"
        link={getLinkById('73YszXz0K7rrqngpQkXSok')}
        handleClick={handleClick}
      />
      <LinkNavigation
        className="px-3 pt-4 pb-3 text-xl text-white md:text-2xl font-title last:mr-0"
        text="contact"
        title="contact"
        link={getLinkById('1MCPxyOPRZmaMwQILIvHCR')}
        handleClick={handleClick}
      />
    </>
  )
}

export default injectIntl(MenuDrawer)
