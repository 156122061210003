// import { useDispatch } from 'react-redux'
// import { resetFilter } from '../../state/app'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'

import flagDe from '../../../static/img/country-de.png'
import flagEn from '../../../static/img/country-en.png'

const localeTitle = {
  de: 'Deutsch',
  en: 'English',
}

const localeFlag = {
  de: flagDe,
  en: flagEn,
}

const Language = ({ languages = [], className = '', handleClick }) => {
  // const dispatch = useDispatch()
  languages =
    languages &&
    languages.length > 0 &&
    languages.sort((a, b) => a.locale > b.locale)

  return (
    <div className="flex items-center mt-6 lg:mt-0">
      {languages &&
        languages.map(({ locale, slug }) => (
          <GatsbyLink
            key={locale}
            to={slug}
            className={`w-6 my-3 mx-2 block ${className}`}
            hrefLang={locale}
            onClick={handleClick}
          >
            <img
              src={localeFlag[locale]}
              alt={localeTitle[locale]}
              className="w-full h-auto"
              style={{ width: 30 }}
              height="128"
              width="128"
            />
          </GatsbyLink>
        ))}
    </div>
  )
}

export default Language
