import React from 'react'

import Container from '../Container'

const Drawer = ({ children, visible }) => {
  return (
    <nav
      className={
        visible
          ? `fixed left-0 right-0 top-0 bg-gray-800 lg:block lg:h-auto h-screen z-40 transition duration-100 ease-out opacity-100 transform translate-x-0 md:py-32 py-20 pointer-events-auto`
          : `fixed left-0 right-0 top-0 bg-gray-800 lg:block lg:h-auto z-40 h-screen transition-transform duration-500 ease-out opacity-0 transform translate-x-1/2 md:py-32 py-20 pointer-events-none`
      }
    >
      <Container>
        <div className="flex flex-col items-center ">{children}</div>
      </Container>
    </nav>
  )
}

export default Drawer
