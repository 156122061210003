import { injectIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import LinkNavigation from '../../components/LinkNavigation'

function Menu({ getLinkById }) {
  return (
    <div className="w-full lg:hidden flex justify-around md:justify-center sticky top-0 z-40 bg-gray-700 px-4">
      <LinkNavigation
        className="px-1 md:px-3 py-3 text-base md:text-lg text-white font-title"
        text="boats"
        title="boats"
        link={getLinkById('6kFcRootiIWCOqPktT3NlD')}
      />
      <LinkNavigation
        className="px-1 md:px-3 py-3 text-base md:text-lg text-white font-title"
        text="boatTours"
        title="boatTours"
        link={getLinkById('23KjwgDylEv6dBb2apqe0S')}
      />
      <LinkNavigation
        className="px-1 md:px-3 py-3 text-base md:text-lg text-white font-title"
        text="faq"
        title="faq"
        link={getLinkById('73YszXz0K7rrqngpQkXSok')}
      />
      <LinkNavigation
        className="px-1 md:px-3 py-3 text-base md:text-lg text-white font-title"
        text="contact"
        title="contact"
        link={getLinkById('1MCPxyOPRZmaMwQILIvHCR')}
      />
    </div>
  )
}

export default injectIntl(Menu)
