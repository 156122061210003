// import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { injectIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import LinkNavigation from '../../components/LinkNavigation'

function Menu({ pathname, getLinkById }) {
  return (
    <>
      <LinkNavigation
        className="hidden px-3 pt-4 pb-3 mr-2 text-base text-white font-title last:mr-0 lg:block after-border-hover"
        text="boats"
        title="boats"
        link={getLinkById('6kFcRootiIWCOqPktT3NlD')}
      />
      <LinkNavigation
        className="hidden px-3 pt-4 pb-3 mr-2 text-base text-white font-title last:mr-0 lg:block after-border-hover"
        text="boatTours"
        title="boatTours"
        link={getLinkById('23KjwgDylEv6dBb2apqe0S')}
      />
      <LinkNavigation
        className="hidden px-3 pt-4 pb-3 mr-2 text-base text-white font-title last:mr-0 lg:block after-border-hover"
        text="faq"
        title="faq"
        link={getLinkById('73YszXz0K7rrqngpQkXSok')}
      />
      <LinkNavigation
        className="hidden px-3 pt-4 pb-3 mr-2 text-base text-white font-title last:mr-0 lg:block after-border-hover"
        text="contact"
        title="contact"
        link={getLinkById('1MCPxyOPRZmaMwQILIvHCR')}
      />
    </>
  )
}

export default injectIntl(Menu)
